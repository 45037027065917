
<template>
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon">
    <circle class="color"  id="Ellipse 50" cx="19" cy="19" r="19" :fill="secondaryColor" :style="{opacity: opacity}"/>
    <path id="Icon_2" fill-rule="evenodd" clip-rule="evenodd" :fill="color" d="M15 26.5791C15 26.2153 15.1327 25.8516 15.3991 25.574L21.7083 19L15.3991 12.426C14.8673 11.8709 14.8673 10.9708 15.3991 10.4157C15.9318 9.86144 16.7955 9.86144 17.3282 10.4157L24.6011 17.9948C25.133 18.55 25.133 19.45 24.6011 20.0052L17.3282 27.5843C16.7955 28.1386 15.9318 28.1386 15.3991 27.5843C15.1327 27.3067 15 26.9429 15 26.5791Z"/>
    </g>
    </svg>
</template>

<script>
export default {
  name: 'Corner2',
  props: {
    color: {
      type: String,
      required: true,
    },
    secondaryColor: {
      type: String,
      required: true,
    },
    opacity: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="css" scoped>
</style>